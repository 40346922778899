<template>
	<div data-component="deal-summary">
		<div
			data-element="deal-header"
			v-if="getShowHeader"
		>
			<div
				data-element="dealer"
				v-if="getDealer"
			>
				<div data-element="label">
					Dealer:
				</div>
				<div data-element="value">
					{{ getDealerString }}
				</div>
			</div>
			<div
				data-element="vulnerability"
				v-if="getVulnerability"
			>
				<div data-element="label">
					Vulnerability:
				</div>
				<div data-element="value">
					{{ getVulnerability }}
				</div>
			</div>
		</div>
		<div data-element="deal-diagrams">
			<deal-matrix
				data-element="diagram"
				:gameState="getGameState"
			/>
			<bidding-table
				data-element="diagram"
				:gameState="getGameState"
			/>
		</div>
		<div
			data-element="deal-footer"
			v-if="getShowFooter"
		>
			<div
				data-element="contract-and-declarer"
				v-if="getShowContractAndDeclarer"
			>
				<div data-element="label">
					Contract:
				</div>
				<contract-and-declarer
					:contract="getContract"
					:declarer="getDeclarer"
				/>
			</div>
			<div
				v-if="getOpeningLead"
				data-element="opening-lead"
			>
				<div data-element="label">
					Opening lead:
				</div>
				<opening-lead
					v-model="getOpeningLead"
				/>
			</div>
		</div>
		<btn
			text="Show in simulator"
			@click.native="showSimulator"
		/>
		<portal
			to="fullscreenModal"
			v-if="simulatorIsVisible"
		>
			<simulator
				:mode="getSimulatorMode"
				:gameId="getGameId"
				:gameState="getGameState"
				:playerPosition="getPlayerPosition"
				:bidExplanations="getBidExplanations"
				:bidHints="getBidHints"
				:declarerHint="getDeclarerHint"
				:openingLead="getOpeningLead"
				:claimParameters="getClaimParameters"
				@close="closeSimulator"
			/>
		</portal>
	</div>
</template>

<script>

	import DealMatrix           from '@/components/diagrams/DealMatrix';
	import BiddingTable         from '@/components/diagrams/BiddingTable';
	import OpeningLead          from '@/components/diagrams/OpeningLead';
	import ContractAndDeclarer  from '@/components/diagrams/ContractAndDeclarer';
	import Btn                  from '@/components/ui/Btn';
	import { seatPositions, SIMULATOR_MODES } from '@/consts';

	export default {
		components: {
			BiddingTable,
			DealMatrix,
			OpeningLead,
			ContractAndDeclarer,
			Btn,
			Simulator: () => import('@/components/simulator/Simulator')
		},
		props: {
			deal: {
				type: Object,
				default: undefined
			},
			mode: {
				type: [String, undefined],
				validator (value) {
					return Object.values(SIMULATOR_MODES).includes(value);
				},
				default: SIMULATOR_MODES.PREVIEW
			},
			gameState: {
				type: Object,
				default: undefined
			}
		},
		data () {
			return {
				simulatorIsVisible: false
			};
		},
		computed: {
			getSimulatorMode () {
				return this.mode;
			},
			getGameId () {
				return this.deal?.id;
			},
			getPlayerPosition () {
				return this.deal?.playerPosition;
			},
			getGameState () {
				if (!this.deal?.deal &&
						!this.gameState) {
					return false;
				}
				if (!this.deal) {
					return this.gameState;
				}
				return this.deal?.deal;
			},
			getBidExplanations () {
				return this.deal?.bidExplanations || [];
			},
			getBidHints () {
				return this.deal?.bidHints || [];
			},
			getDeclarerHint () {
				return this.deal?.declarerHint || '';
			},
			getContract () {
				return this.deal?.contract;
			},
			getDeclarer () {
				return this.deal?.declarer;
			},
			getOpeningLead () {
				return this.deal?.openingLead;
			},
			getClaimParameters () {
				return this.deal?.claimParameters || [];
			},
			getVulnerability () {
				return this.getGameState?.vulnerable;
			},
			getDealerString () {
				if (!this.getDealer) {
					return false;
				}
				if (this.getDealer.length === 1) {
					return seatPositions.find((position) => {
						return position.value === this.getDealer;
					}).text;
				}
				return this.getDealer;
			},
			getDealer () {
				return this.deal?.dealer;
			},
			getShowHeader () {
				if (!this.getDealer &&
						!this.getVulnerability) {
					return false;
				}
				return true;
			},
			getShowContractAndDeclarer () {
				if (!this.getContract ||
						!this.getDeclarer) {
					return false;
				}
				return true;
			},
			getShowFooter () {
				if (!this.getShowContractAndDeclarer &&
						!this.openingLead) {
					return false;
				}
				return true;
			},
			getRecordingInProgress () {
				return this.$store.getters['screenRecorder/getRecordingInProgress'];
			}
		},
		methods: {
			showSimulator () {
				this.simulatorIsVisible = true;
			},
			closeSimulator () {
				if (this.getRecordingInProgress) {
					if (confirm('If you close the simulator, screen recording will be stopped. Are you sure?')) {
						this.$store.dispatch('screenRecorder/stopRecording');
						this.simulatorIsVisible = false;
					}
					// Do nothing
				} else {
					this.simulatorIsVisible = false;
				}
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='deal-summary'] {
		display:flex;
		flex-direction:column;
		align-items:center;
		justify-content:center;
		[data-element='label'] {
			margin-right:0.5em;
		}
		[data-element='deal-header'] {
			display:flex;
			align-items:center;
			width:100%;
			margin:0 0 rem(24);
			padding:rem(4) 0;
			border-top:2px solid $c-text;
			border-bottom:2px solid $c-text;
			font-size:rem(14);
			line-height:1;
			font-weight:700;
			[data-element='dealer'] {
				display:flex;
				align-items:center;
				justify-content:flex-end;
				margin:auto auto auto 0;
			}
			[data-element='vulnerability'] {
				display:flex;
				align-items:center;
				justify-content:flex-end;
				margin:auto 0 auto auto;
			}
		}
		[data-element='deal-diagrams'] {
			display:flex;
			flex-direction:column;
			align-items:center;
			justify-content:center;
			width:100%;
			[data-component='deal-matrix'] {
				margin:0 0 rem(24);
			}
			[data-component='bidding-table'] {
			}
		}
		[data-element='deal-footer'] {
			display:flex;
			align-items:center;
			margin:rem(24) 0 0;
			padding:rem(4) 0;
			border-top:2px solid $c-text;
			border-bottom:2px solid $c-text;
			width:100%;
			font-size:rem(14);
			line-height:1;
			font-weight:700;
			[data-element='contract-and-declarer'] {
				display:flex;
				align-items:center;
				justify-content:flex-end;
				margin:auto auto auto 0;
				[data-component='contract-and-declarer'] {
				}
			}
			[data-element='opening-lead'] {
				display:flex;
				align-items:center;
				justify-content:flex-end;
				margin:auto 0 auto auto;
				[data-component='opening-lead'] {
				}
			}
		}
		[data-component='btn'] {
			margin-top:rem(12);
		}
	}

</style>
